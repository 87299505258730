import Portrait from '../images/trees.png'


const Home = () => {
    return (
      <>
      <div className="center main-content section-home">
          <div className='hero-section'>
            <img className='hero-image' src={Portrait} alt="Kelly Klem Portait"></img>
          </div>
          <div className='hero-section'>
            <ul style={{marginTop:'auto',marginBottom:'auto', padding:'0px'}}>
              <h1 className='text-center'> Kelly Klem</h1>
              <h3 className='text-center home-sub-header'>Educator, Writer, & Instructional Designer</h3>
            </ul>
          </div>
      </div>
      <p className='home-footer'>
        This website is a collection of my professional documents, teaching resources, and educational essays, as well as my own creative writing samples.
      </p>
      </>
    );
  }
  
  export default Home;
  