import protest from '../images/resources/EducationalProtest.jpeg';
import schoolHouse from '../images/resources/HistoricalSchoolHouse.jpeg';
import studentsAtDesks from '../images/resources/StudentsAtDesks.jpeg';
import studentsInClass from '../images/resources/StudentsInClass.jpeg';
import Iep504Chart from '../images/resources/IEPvs504.png';
import OSPI from '../images/resources/OSPIImage.png';
import FiveStep from '../images/resources/5-StepImage.png';
import AcademicLang from '../images/resources/AcademicLanguageImage.png';
import InstructionalStrategies from '../images/resources/InstructionalStrategiesList.png'
import StudentHealthImpairments from '../images/resources/StudentHealthImpairmentsImage.png'

const GetResourceSection = (sectionInfo, isEven) => {
    return (
      <section key={sectionInfo.heading}>
        <div className={`section ${isEven ? 'even' : 'odd'}`}>
          <div className='text-content'>
            <h2>{sectionInfo.heading}</h2>
            <h3>{sectionInfo.subHeading}</h3>
            <p className='publication-content'>{sectionInfo.context}</p>
            <a href={sectionInfo.readLink} target="_blank" rel='noreferrer' className='read-button'>Read More</a>
          </div>
          <div className='image-content resource-image'>
            <img className={`${isEven ? 'even' : 'odd'}`} src={sectionInfo.imgLink} alt={sectionInfo.heading}></img>
          </div>
        </div>
        <div className="separator"></div>
      </section>
    )
  }

const resources = [
    {
      heading: 'Instructional Strategies List',
      subHeading: 'A comprehensive guide to various learning strategies and their implementation techniques.',
      context: 'Here is a list that provides 52 different learning strategies for teachers to apply within the classroom setting. These strategies take into accommodation students with varied needs and can be applied to learners of all ages. This list also provides examples of activity examples to pair with the instructional strategies.',
      imgLink: InstructionalStrategies,
      readLink: 'https://drive.google.com/file/d/15hSugZBlqeWqabp-ltV1BI2L8yyRaXp8/view?usp=sharing',
    },
    {
      heading: 'Student Health Impairments',
      subHeading: 'A guide to understanding the impact of student health impairments on academic ability.',
      context: 'This is a PowerPoint presentation that provides learners with context and information regarding various health impairments and how these health conditions affect student performance. The presentation includes examples of health impairments and suggestions to provide accommodations for learners.',
      imgLink: StudentHealthImpairments,
      readLink: 'https://drive.google.com/file/d/135u6CE1bAvvoFES5-BmPfoV_lxdrfVok/view?usp=sharing',
    },
    {
      heading: '5-Step Lesson Plan Outline',
      subHeading: 'A lesson plan outline that can be applied to various lessons.',
      context: 'This is a written 5-step lesson plan outline that can be applied to a variety of disciplines and utilized for a wide range of learners. Designed for adult learners, this template provides guiding questions for both creating curriculum for lesson plans and guiding prompts to evaluate student progress.  The 5-step lesson plan outline is applicable for both educators and learners, and serves as a scaffold and base for lesson planning and instructional design.',
      imgLink: FiveStep,
      readLink: 'https://drive.google.com/file/d/1KLSSn5vHI8oc95929cU2RISMut-k0aJB/view?usp=sharing',
    },
    {
      heading: 'Academic Language',
      subHeading: 'Vocabulary used in a language arts settings.',
      context: 'This document provides an explanation of what academic is and how it is crucial to understanding new lessons and materials and provides examples for grades 6 -12. This resource provides a list of the academic language related to an educational setting and provides examples of how to incorporate academic language into your lesson plans and to solidify student understanding relating to a text.',
      imgLink: AcademicLang,
      readLink: 'https://drive.google.com/file/d/1ryh6TV8OGNfnBjH6Npw2wTRzvJ-nxYO1/view?usp=sharing',
    },
    {
      heading: 'State certified transcirpt',
      subHeading: 'OPSI certified professional development.',
      context: 'This is a transcript that reflects the various state-certified courses and pieces of training I have completed. The courses consisted of synchronous, asynchronous, remote, and in-person training. ',
      imgLink: OSPI,
      readLink: 'https://drive.google.com/file/d/1pwvwHwONuS1rbz1aIfs5DIVIWYuQbPA-/view?usp=sharing',
    },
    {
      heading: 'Pedagogical Efficacy Across Diverse Learner Profiles',
      subHeading: 'An analysis of diverse learner profiles.',
      context: 'This is a document that analyzes various learning profiles of students and discusses observational learning, constructivist paradigms, self-efficacy, and interpersonal dynamics. Neuroplasticity, and motivations within the classroom. This writing sample focuses on several case studies performed in classrooms on diverse groups of learners.',
      imgLink: protest,
      readLink: 'https://drive.google.com/file/d/1ltd3m_Q4VyxQZE1LAvPIBX-jdcQDZidQ/view?usp=sharing',
    },
    {
      heading: 'Social Reform: The Major Impacts of the Civil Rights Movement on Education',
      subHeading: 'Written analysis paper presented to the Department of Education at Western Washington University.',
      context: 'This paper observes and divulges studies of the complex relationship between U.S. federal policies and promoting equity in the public school system both in the past and today. Topics discussed include educational policies, the desegregation of public schools, social experiments, and the implementation of the ESEA policies in school settings',
      imgLink: schoolHouse,
      readLink: 'https://drive.google.com/file/d/1vJACg4fuMsJDyMWe-LaIExzl0KkZRyt5/view?usp=sharing',
    },
    {
      heading: 'Book Critique: Human Learning by Jeanne Ormrod',
      subHeading: 'Critique presented to the Eastern Washington University’s Department of Education.',
      context: 'This is an analysis of Jeanne Ormrod’s ‘Human Learning’ education textbook which focuses on the learning theories described and how they can be applied to classroom settings of all ages and disciplines. In the critique, Ormrod’s 8th edition of this textbook is analyzed on its structure and the relevance of its theories applied',
      imgLink: studentsAtDesks,
      readLink: 'https://drive.google.com/file/d/1HmNNenf7CBnwvlMUQnUTSW9pqh-qUeHJ/view?usp=sharing',
    },
    {
      heading: 'The Common School Movement',
      subHeading: 'A concise visual slideshow presentation presented to the post-secondary classroom.',
      context: 'This concise presentation covers the foundations of Common Schools in the United States during the initial formative years of our public education institution. These early schools were typically one-roomed schoolhouses that grouped students of all ages and mixed religious teachings with academic disciplines.',
      imgLink: studentsInClass,
      readLink: 'https://drive.google.com/file/d/1mMzQvhkEKyvf-giYus2QmahoNB4yqxpd/view?usp=sharing'
    },
    {
      heading: 'IEP vs. 504 Plan',
      subHeading: 'A chart that distinguishes Individualized Education Plans from Section 504 accommodation plans.',
      context: 'This concise chart embeds links to federal policies that effect school requirements to provide accommodations and supports to students of all ages. This graphic organizer includes differences between the two programs including eligibility, provisions, evaluations, and more. ',
      imgLink: Iep504Chart,
      readLink: 'https://drive.google.com/file/d/1ZdarAiUlDVz6VrLrPLglkJKmNk0_dEC2/view?usp=sharing'
    }
  ]

const Resources = () => {
    return (
        <>
            <div className="banner-gradient"></div>
            <div className="center main-content">
                <section >
                    <div className="center resource-header">
                        <h1>Education Resources</h1>
                        <p>
                            This displays several samples of my educational research writing, educational essays, critiques, instructional design, and curriculum samples. In this portfolio, some of the educational writing was published and/or written for Western Washington University, Eastern Washington University, and to develop personal higher-educational understanding. I hope you take the time to browse these portfolio pieces as they demonstrate my writing versatility.                        
                        </p>
                    </div>
                    <div className="separator-thin"></div>
                    {resources.map((resource, index) => (GetResourceSection(resource, index % 2 === 0)))}
                </section>
            </div>
        </>
    );
}

export default Resources;