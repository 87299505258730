import { Outlet, NavLink } from "react-router-dom";
import React, {useState} from 'react';
import '../Style/NavMenu.css';
import useScreenSize from '../Components/useScreenSize';
import { MenuOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd-v5';

const NavMenu = () => {
  return (
    <ul>
    <li>
      <NavLink to="/" className={(navData) => (navData.isActive ? "active" : '')}>Home</NavLink>
    </li>
    <div className="separator-nav"></div>
    <li>
      <NavLink to="/publications" className={(navData) => (navData.isActive ? "active" : '')}>Publications</NavLink>
    </li>
    <div className="separator-nav"></div>
    <li>
      <NavLink to="/resources" className={(navData) => (navData.isActive ? "active" : '')}>Education Resources</NavLink>
    </li>
    <div className="separator-nav"></div>
    <li>
      <NavLink to="/instructional" className={(navData) => (navData.isActive ? "active" : '')}>Instructional Design</NavLink>
    </li>
    <div className="separator-nav"></div>
    <li>
      <NavLink to="/about" className={(navData) => (navData.isActive ? "active" : '')}>About</NavLink>
    </li>
  </ul>
  )
}

const NavButton = (isOpen, toggleMenu) => {
  return (
    <>
    <div className="menu-button">
      <MenuOutlined style={{fontSize:'24px',padding:'12px'}} onClick={toggleMenu}/>
    </div>
      {isOpen && 
      <nav className="sticky-menu" style={{marginTop:'48px'}}>
        <NavMenu/>
      </nav>
      }
    </>
  );
}

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  const screenSize = useScreenSize();
  return (
    <>
        {
          screenSize.width > 1200 
          ? <nav className="sticky-menu"><NavMenu/></nav> 
          : NavButton(isOpen, toggleMenu)
        }
        <FloatButton.BackTop />
      <Outlet />
    </>
  )
};

export default Layout;
