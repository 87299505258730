const NoPage = () => {
  return (
    <>
    <div className="center main-content">
        <h2 style={{textAlign:'center'}}>404: Page not found</h2>
    </div>
    <p className='home-footer'>
      This website is a collection of my professional documents, teaching resources, and educational essays, as well as my own creative writing samples.
    </p>
    </>
  );
  };
  
  export default NoPage;
  