import React from 'react';
import davidsFavorites from '../images/instructionaldesign/DavidsFavorites.png'
import DistressToleranceBefore from '../images/instructionaldesign/DistressToleranceBefore.png'
import DistressToleranceAfter from '../images/instructionaldesign/DistressToleranceAfter.png' 
import CreatingCulture from '../images/instructionaldesign/CreatingAProfessionalCulture.png'
import CriticalReviewInstructionalSequence from '../images/instructionaldesign/CriticalReviewInstructionalSequence.png'
import OrganizingGoogleClassroomforLearnerSuccess from '../images/instructionaldesign/OrganizingGoogleClassroomforLearnerSuccess.png'
import TraumaInformedSchoolsImage from '../images/instructionaldesign/TraumaInformedSchoolsImage.png'

const GetIDSection = (sectionInfo, isEven) => {
  return (
    <section key={sectionInfo.heading}>
      <div className={`section ${isEven ? 'even' : 'odd'}`}>
        <div className='text-content'>
          <h2>{sectionInfo.heading}</h2>
          <h3>{sectionInfo.subHeading}</h3>
          <p className='publication-content'>{sectionInfo.context}</p>
          { sectionInfo.isbn != null && (<h3 className='isbn'>{`ISBN: ${sectionInfo.isbn}`}</h3>)}
          <a href={sectionInfo.readLink} target="_blank" rel='noreferrer' className='read-button'>Read More</a>
        </div>
        <div className='image-content'>
          <img className={`${isEven ? 'even' : 'odd'}`} src={sectionInfo.imgLink} alt={sectionInfo.heading}></img>
        </div>
      </div>
      <div className="separator"></div>
    </section>
  )
}

const GetIDBeforeAfterSection = (sectionInfo) => {
  return (
    <section key={sectionInfo.heading}>
      <div className={'section'} style={{'display':'block'}}>
        <h2>{sectionInfo.heading}</h2>
        <h3>{sectionInfo.subHeading}</h3>
        <div style={{'marginTop':'34px'}} className='container'>
          <div className='image-container'>
            <h3>Client Original</h3>
            <img className='before-after-image' src={sectionInfo.beforeImg} alt={sectionInfo.heading + "-before"}/>
            <a style={{'float':'left'}} href={sectionInfo.beforeLink} target="_blank" rel='noreferrer' className='read-button'>Read More</a>
          </div>
          <div className='image-container'>
            <h3>My Revision</h3>
            <img className='before-after-image' src={sectionInfo.afterImg} alt={sectionInfo.heading + "-after"}/>
            <a style={{'display':'inline-block', 'float':'right'}} href={sectionInfo.afterLink} target="_blank" rel='noreferrer' className='read-button'>Read More</a>
          </div>
        </div>
        <p className='publication-content'>{sectionInfo.context}</p>
      </div>
      <div className="separator"></div>
    </section>
  )
}

const designs = [
  {
    creator: (info, even) => GetIDBeforeAfterSection(info),
    heading: "Client Instructional Design Rework : Before and After",
    subHeading: "",
    context: "In this project, I was tasked by the client to streamline their presentation while making it more informational as well as visually pleasing. In the presentation, I incorporated visual representations of some of the points being made and altered the presentation to be both uniform and consistent. This is a 'before and after' document draft of both the original client presentation and the version I presented after numerous modifications.",
    beforeImg: DistressToleranceBefore,
    beforeLink: 'https://drive.google.com/file/d/1YIIlKdwmuLevHpBt-CFjWfFbF-cllK7f/view?usp=drive_link',
    afterLink: 'https://drive.google.com/file/d/1BJvvVY8ODBZNvhCmsaqABooKJ2mj1cAs/view?usp=drive_link',
    afterImg: DistressToleranceAfter,
  },
  {
    creator: (info, even) => GetIDSection(info, even),
    heading: "Creating a Professional Culture Based on Social-Emotional Learning.",
    subHeading: "",
    context: "A slideshow course on best practices for creating a professional culture that is based on social-emotional learning. This course has learning objectives that include recognizing cultural awareness, learning self-care strategies, understanding relationships, and creating plans of action to improve self-practices and contribute towards stakeholder goals.",
    imgLink: CreatingCulture,
    readLink: 'https://drive.google.com/file/d/1cJrZOK2GPu7T33sE_xNsCJUQrASmjmTn/view?usp=drive_link',
  },
  {
    creator: (info, even) => GetIDSection(info, even),
    heading: "Organizing Google Classroom for Learner Success",
    subHeading: "",
    context: "A brief tutorial on ways educators can utilize the features of Google Classroom to set up their course resources for learner success. This presentation has various ways to add accommodations, sort resources, and structure communications within the digital classroom.",
    imgLink: OrganizingGoogleClassroomforLearnerSuccess,
    readLink: 'https://drive.google.com/file/d/1plVr8Ul6N542sS7TiYuzsgUM15dmn8bw/view?usp=drive_link',
  },  
  {
    creator: (info, even) => GetIDSection(info, even),
    heading: "Sample of Cookbook Instructions.",
    subHeading: "David's Cookbook",
    context: "This is a sample from a client's project of creating their own personal cookbook about personal family recipes.",
    imgLink: davidsFavorites,
    readLink: 'https://drive.google.com/file/d/1wSU0J_O4jInYvjpe_0lY60L5flrcsgpC/view?usp=drive_link',
  },
  {
    creator: (info, even) => GetIDSection(info, even),
    heading: "Critical Review Instructional Sequence.",
    subHeading: "",
    context: "This is a premade curriculum for use in a secondary-aged classroom, which can be modified for post-secondary usage. This course outline incorporates Common Core Standards, while introducing students to critique, hooks, thesis, summaries, and other domain-specific vocabulary. The course outline includes referenced texts, timelines, and rubrics for assessment.",
    imgLink: CriticalReviewInstructionalSequence,
    readLink: 'https://drive.google.com/file/d/1uWBpYzLycGRIBwXwMF4hlyU4jnOa04pS/view?usp=drive_link',
  },
  {
    creator: (info, even) => GetIDSection(info, even),
    heading: "Trauma-Informed Practices & SEL Course.",
    subHeading: "",
    context: "A comprehensive outline for a course that covers the importance of trauma-informed practices in a school setting. These modules also describe how social-emotional learning is beneficial for students in a school setting. This course outline can be easily transferred into an LMS system such as Canvas or Google Classroom.",
    imgLink: TraumaInformedSchoolsImage,
    readLink: 'https://docs.google.com/document/d/1rKszZ4ED7CkcFZeiFznpFzSrGU5PZdk14bvz_PriR1o/edit?usp=sharing',
  },
]

const InstructionalDesign = () => {
  return (
    <>
      <div className="banner-gradient"></div>
      <div className="center main-content">
        <section >
          <div className="center publication-header">
            <h1>Instructional Design</h1>
            <p>As an accomplished instructional designer, I bring experience in revising, expanding, and authoring instructional materials tailored to the specific needs of my clients and learners. My portfolio showcases a variety of projects where I've transformed educational content to enhance learning outcomes and engagement. Trained and proficient in developing materials for diverse Learning Management Systems (LMS), I leverage my extensive background in graphic design and instructional planning to create visually compelling and pedagogically sound e-learning experiences. From initial concept through to execution, my work demonstrates a deep understanding of how to make complex information accessible and engaging for learners across various educational settings.</p>
          </div>
          <div className="separator-thin"></div>
          {designs.map((design, index) => design.creator(design, index % 2 === 0))}
        </section>
      </div>
    </>
  )
};
  
export default InstructionalDesign;