import React from 'react';
import catsBitingPlants from '../images/publications/CatsBitingPlantsColoringBook.png';
import greatAmercianArtists from '../images/publications/GreatAmericanArtistsforKids.jpg';
import jeopardy54 from '../images/publications/Jeopardy54.jpg';
import lyndenTribune from '../images/publications/LyndenTribuneImage.jpeg';
import roadTripActivities from '../images/publications/RoadTripActivityBookCover.png';
import withHonors from '../images/publications/WithHonors.jpg'
import eloquence from '../images/publications/Eloquence.jpg'

const GetPublicationSection = (sectionInfo, isEven) => {
  return (
    <section key={sectionInfo.heading}>
      <div className={`section ${isEven ? 'even' : 'odd'}`}>
        <div className='text-content'>
          <h2>{sectionInfo.heading}</h2>
          <h3>{sectionInfo.subHeading}</h3>
          <p className='publication-content'>{sectionInfo.context}</p>
          { sectionInfo.isbn != null && (<h3 className='isbn'>{`ISBN: ${sectionInfo.isbn}`}</h3>)}
          <a href={sectionInfo.readLink} target="_blank" rel='noreferrer' className='read-button'>Read More</a>
        </div>
        <div className='image-content'>
          <img className={`${isEven ? 'even' : 'odd'}`} src={sectionInfo.imgLink} alt={sectionInfo.heading}></img>
        </div>
      </div>
      <div className="separator"></div>
    </section>
  )
}

const publications = [
  {
    heading: 'Cats Biting Plants',
    subHeading: 'A coloring book for all ages.',
    context: 'Cats Biting Plants is a collection of drawings created by Kelly Klem intended to be colored by both children and adults alike. Drawing inspiration from her own surroundings, Klem creates whimsical complex drawings that reflect the frustrations that many plant and pet owners experience. This coloring book is available on Amazon and local stores.',
    imgLink: catsBitingPlants,
    readLink: 'https://www.amazon.com/dp/B0CNTJGLVC',
    isbn: '979-8868356186'
  },
  {
    heading: 'Road Trip Activity Book',
    subHeading: 'Collection of Traveling Activities for Youth',
    context: 'Road Trip Activity Book is a collection of activities that include writing prompts, drawing prompts, educational games, coloring pages, and other activities for travelers to do in the car. This is a publication through Amazon where the books are distributed nationally and locally.',
    imgLink: roadTripActivities,
    readLink: 'https://www.amazon.com/gp/product/B0CR3XKWQH',
    isbn: '979-8871890943'
  },
  {
    heading: 'Jeopardy Magazine',
    subHeading: '54th edition 2018',
    context: 'Jeopardy Magazine is an annual publication that showcases the unique talents of Western Washington University. This magazine is an anthology of creative works that include an array of mediums. First published in 1965, Jeopardy Magazine has a standing legacy of representing the various voices of Western’s diverse community. Featured in here is Kelly Klem’s art titled, ‘Colorless in Zurich.’',
    imgLink: jeopardy54,
    readLink: 'https://jeopardy.wwu.edu/issue-54',
  },
  {
    heading: 'Country Life Monthly Column',
    subHeading: 'Lynden Tribune, 2013 - 2016',
    context: 'Country Life Monthly is a longstanding monthly column tradition of the Lynden Tribune. This column shares local news, clever anecdotes, and other descriptions of Whatcom County’s country living. Kelly Klem wrote monthly articles for the Lynden newspaper.',
    imgLink: lyndenTribune,
    readLink: 'https://issuu.com/lyndentribune/docs/country_life_02.10.16'
  },
  {
    heading: 'Eloquence',
    subHeading: 'Poetry Publication 2015',
    context: 'Eloquence is a national collection of poems written by youths 18 years old and younger. This is an anthology of select poems from various ages and backgrounds used to showcase the works of young poets. Kelly Klem has poetry published in this book titled, “First Pet.”',
    imgLink: eloquence,
    readLink: 'https://www.amazon.com/Eloquence-Poetry-Collection-America-Library/dp/0996684107'
  },
  {
    heading: 'Great American Artists for Kids',
    subHeading: 'Art Publication 2009',
    context: 'This publication is a collection of art activities and art history that is written with kids being the focus audience. Great American Artists for Kids is an anthology of various bibliographical summaries of the careers of many great artists. Kelly Klem’s publication in this book includes visual sketch depictions of several great American artists.',
    imgLink: greatAmercianArtists,
    readLink: 'https://www.amazon.com/Great-American-Artists-Kids-Hands/dp/0935607005'
  },
  {
    heading: 'With Honors',
    subHeading: 'Poem Publication',
    context: 'With Honors is a national collection of poems written by youths 18 years old and younger. This is an anthology of select poems from various ages and backgrounds used to showcase the works of young poets. Kelly Klem had her first poetry publishing in this book with her poem, “Our Planet Earth.”',
    imgLink: withHonors,
    readLink: 'https://www.amazon.com/Honors-2007-Poetry-Collection/dp/0977366227'
  }
]

const Publications = () => {
  return (
    <>
      <div className="banner-gradient"></div>
      <div className="center main-content">
        <section >
          <div className="center publication-header">
            <h1>Publications</h1>
            <p>
              This page serves as a showcase of my previous writing publications. As you browse through this collection, you'll find an array of projects spanning various genres and platforms – all of which have been previously published or commissioned. The intent behind this display is not only to provide a comprehensive insight into my professional journey but also to offer prospective collaborators and clients a glimpse of the caliber and versatility of my work. I invite you to explore, reflect upon, and engage with these samples.
            </p>
          </div>
          <div className="separator-thin"></div>
          {publications.map((publication, index) => (GetPublicationSection(publication, index % 2 === 0)))}
        </section>
      </div>
    </>
  )
};

export default Publications;
