import HeroImage from '../images/kelly1.jpg'

const aboutTextStyle = {
    'marginBottom':'auto',
    'marginTop':'auto',
}

const About = () => {
    return (
        <>
            <div className="center main-content section-home">
                    <div className='hero-section'>
                        <ul style={{marginLeft:'auto' }}>
                            <h1>About me</h1>
                            <img className='portrait-image' src={HeroImage} alt="Kelly Klem"></img>
                        </ul>
                    </div>
                    <div className='hero-section'>
                        <div style={aboutTextStyle}>
                            <p className='about-me'>Welcome to my site, where you can find a collection of my educational professional documents such as curriculums, teaching resources, and educational essays, as well as my own creative writing samples. This is a space where I share my passion for teaching and writing, and I hope to inspire others to do the same. Feel free to browse my portfolio and get in touch if you have any questions or comments.</p>
                            <a href="https://drive.google.com/file/d/1pxyVGvs63g8B1EGWS4-c4q5oT_0fjlQ8/view?usp=drive_link" target="_blank" rel='noreferrer' className='statement-button'>Philosophy Statement</a>
                        </div>
                    </div>                
            </div>
            <p className='home-footer'>
                This website is a collection of my professional documents, teaching resources, and educational essays, as well as my own creative writing samples.
            </p>
        </>
    );
}

export default About;